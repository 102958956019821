<template>
  <div class="p-sign-up_container">
    <div class="p-sign-up_header">
      <img src="../../assets/logo/color.svg" />
      <WriteUs />
    </div>
    <Stepper v-if="!inviteError" :step="step" :texts="$t('registration.stepper')" />
    <div v-if="!inviteError" class="p-sign-up_content">
      <span v-if="step === 3" class="p-sign-up_content__title p-sign-up_content__title__green">
        {{ $t("registration.step3.head") }}
      </span>
      <span class="p-sign-up_content__title">{{ stepTexts.title }}</span>
      <span class="p-sign-up_content__sub-title">
        {{ stepTexts.subTitle }}
        <router-link v-if="step === 3" to="/auth/signin">{{ $t("registration.step3.subtitleLink") }}</router-link>
      </span>
      <v-card v-if="step !== 3" class="p-sign-up_content_card" outlined>
        <span class="p-sign-up_content_card__title">
          {{ stepTexts.cardTitle }}
        </span>
        <FirstStep v-if="step === 1" :email="invite.email" @checkoutStep="checkoutStep" @saveValue="saveFirstStep" />
        <SecondStep v-if="step === 2" :isLoading="loading" :propErrors="validatorErrors" @saveValues="saveSecondStep" />
      </v-card>
    </div>
    <PageNotFound v-else />
    <Snackbar v-model="snackbar" :text="error" :timeout="3000" type="error" @toggleSnackbar="toggleSnackbar" />
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import Stepper from "@/components/shared/Stepper";
import { validatorErrors } from "@/mixins/validatorErrors";
import { useAppStore } from "@/pinia-store/app";
import { useInvitesStore } from "@/pinia-store/invites";
import { usePatientsStore } from "@/pinia-store/patients";
import { isValidPhoneNumber } from "@/utils/validations";

import PageNotFound from "../shared/PageNotFound";
import Snackbar from "../shared/Snackbar";
import WriteUs from "../shared/WriteUs";
import FirstStep from "./FirstStepForm";
import SecondStep from "./SecondStepForm";

export default {
  name: "PatientSignUp",
  components: {
    WriteUs,
    Stepper,
    FirstStep,
    SecondStep,
    Snackbar,
    PageNotFound,
  },
  mixins: [validatorErrors],
  data: () => ({
    step: 1,
    password: "",
    state: "",
    dateOfBirth: null,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: undefined,
    address: "",
    timerId: null,
    idFileDriverLicenseFront: "",
    idFileDriverLicenseBack: "",
    error: "",
    loading: false,
    snackbar: false,
    inviteError: "",
  }),
  computed: {
    ...mapState(useInvitesStore, ["invite"]),
    ...mapState(useAppStore, ["brandName"]),

    stepTexts() {
      let texts = {};
      switch (this.step) {
        case 1:
          texts = {
            title: this.$t("registration.step1.title", { brandName: this.brandName }),
            subTitle: this.$t("registration.step1.subtitle"),
            cardTitle: this.$t("registration.step1.form.head"),
          };
          break;
        case 2:
          texts = {
            title: this.$t("registration.step2.title"),
            subTitle: this.$t("registration.step2.subtitle"),
            cardTitle: this.$t("registration.step2.form.head"),
          };
          break;
        case 3:
          texts = {
            title: this.$t("registration.step3.title"),
            subTitle: this.$t("registration.step3.subtitle"),
          };
      }
      return texts;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["getInvite"]),
    ...mapActions(usePatientsStore, ["signUpPatient"]),
    toggleSnackbar() {
      this.snackbar = !this.snackbar;
    },
    checkoutStep(step) {
      this.step = step;
    },
    saveFirstStep(password) {
      this.password = password;
    },
    async redirectLogin() {
      await this.$router.push({ path: "/auth/signin" });
    },
    saveSecondStep(data) {
      this.state = data.selectedState;
      this.city = data.city;
      this.dateOfBirth = moment(data.dateOfBirth).utc(true).format("YYYY-MM-DD");
      this.postalCode = data.postalCode;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.homePhoneNumber = data.homePhoneNumber;
      this.phoneNumber = data.phoneNumber;
      this.gender = data.gender;
      this.address = data.address;
      this.idFileDriverLicenseFront = data.idFileDriverLicenseFront;
      this.idFileDriverLicenseBack = data.idFileDriverLicenseBack;
      this.submitSignUp();
    },
    async submitSignUp() {
      this.loading = true;
      this.setResponseErrors([]);
      try {
        const patient = {
          inviteId: this.invite.id,
          password: this.password,
          patient: {
            firstName: this.firstName,
            lastName: this.lastName,
            city: this.city,
            postalCode: this.postalCode,
            gender: this.gender,
            birthDate: this.dateOfBirth,
            homePhoneNumber: this.homePhoneNumber,
            phoneNumber: this.phoneNumber,
            state: this.state,
            address: this.address,
            idFileDriverLicenseFront: this.idFileDriverLicenseFront,
            idFileDriverLicenseBack: this.idFileDriverLicenseBack,
          },
        };
        await this.signUpPatient(patient);
        this.step = 3;
        this.timerId = setTimeout(async () => {
          await this.$router.push({ path: "/auth/signin" });
        }, 3000);
      } catch (err) {
        this.setResponseErrors(err);
        this.step = 2;
        if (err?.message?.length && !err?.message.map) {
          this.error = err.message;
          this.toggleSnackbar(err.message);
        } else if (err?.error?.length) {
          this.error = err?.error;
          this.toggleSnackbar(err?.error);
        }

        if (this.timerId) {
          clearTimeout(this.timerId);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const { inviteId } = this.$route.params;
    try {
      await this.getInvite(inviteId);
      if (this.invite.isUsed) await this.redirectLogin();
      else {
        if (this.invite.phoneNumber?.length && isValidPhoneNumber(this.invite.phoneNumber)) {
          this.step = 2;
        } else this.step = 1;
      }
    } catch (err) {
      this.inviteError = err.message;
    }
  },
};
</script>
<style lang="scss">
$signup-header-height: 67px;
.p-sign-up {
  &_container {
    width: 100%;
    height: 100vh;
  }

  &_header {
    width: 100%;
    height: $signup-header-height;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6rem;

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  &_content {
    width: 100%;
    min-height: calc(100% - #{$signup-header-height * 2 + 3});
    padding: 2rem;
    background-color: $white-light-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &_card {
      margin-top: 30px;
      max-width: 464px;
      padding: 1rem 1.5rem 2rem 1.5rem;
      width: 50%;
      text-align: center;

      @media (max-width: 767.98px) {
        width: 100%;
      }

      &__title {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 24px;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    &__title {
      max-width: 416px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 34px;
      text-align: center;
      color: $primaryblack3;
      margin-bottom: 15px;

      @media (max-width: 767.98px) {
        font-size: 20px;
        line-height: 24px;
      }

      &__green {
        color: $success;
      }
    }

    &__sub-title {
      max-width: 502px;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.32px;
      opacity: 0.6;
    }
  }
}

@media (min-width: 1281px) {
  .p-sign-up {
    &_content {
      &_card {
        margin-top: 10px;
      }

      &__title {
        max-width: 45%;
        margin-bottom: 5px;
      }

      &__sub-title {
        max-width: 50%;
      }
    }
  }
}
</style>
